import Header from "../components/Header";
import FeedbackChart from "../components/FeedbackChart";

function Dashboard() {
  return (
    <div className="dark">
      <Header />
      <div>
        <FeedbackChart />
      </div>
    </div>
  );
}

export default Dashboard;
