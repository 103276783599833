import { Box, Button, Card, Divider, Typography } from "@mui/material";
import { TableComponent } from "./TableComponent";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadExcel } from "../utils/downloadExcel";

export const DSEWiseNPSTable = ({ dseWiseNPS }) => {
  return (
    <div className="dark">
      <Card
        variant="outlined"
        sx={{
          boxShadow: 3,
          width: "100%",
          p: "15px",
          background: "#1d2637",
          color: "white",
          fontFamily: "Montserrat, serif !important",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          alignSelf={"flex-end"}
          justifyContent="space-between"
          marginBottom={2}
          marginTop={2}
        >
          <Typography
            variant="overline"
            gutterBottom
            sx={{ color: "white", fontFamily: "Montserrat, serif !important" }}
          >
            Sales Executive-Wise NPS Data
          </Typography>
        </Box>

        <Divider />
        <Box
          display="flex"
          alignItems="center"
          alignSelf={"flex-end"}
          justifyContent="start"
          marginBottom={2}
          marginTop={2}
        >
          <Button
            variant="outlined"
            color="#e62552"
            onClick={() => downloadExcel(dseWiseNPS, "dse-wise-nps")}
            startIcon={<DownloadIcon />}
            sx={{ fontFamily: "Montserrat, serif !important" }}
          >
            Export Sales Executive-Wise NPS Data
          </Button>
        </Box>

        <TableComponent data={dseWiseNPS} />
      </Card>
    </div>
  );
};
