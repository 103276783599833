import React, { useRef, useState } from "react";
import { Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { userData } from "../recoil/atom/userData";
import { useRecoilValue } from "recoil";
import { fileUploadService } from "../service/fileUploadService";
import { Loader } from "./Loader";
import { useFileUploadService } from "../hooks/useFileUploadService";

const FileUpload = () => {
  const user = useRecoilValue(userData);

  const {
    uploadFile,
    selectedFile,
    handleFileChange,
    isLoading,
    severity,
    alertOpen,
    alertMessage,
    feedbackType,
    setFeedbackType,
    fileInputRef,
  } = useFileUploadService();

  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/dashboard/sales");
  };
  const handleFileUpload = async () => {
    await uploadFile();
  };

  return (
    <div className="flex flex-col items-center justify-center p-2 w-1/2 rounded-sm m-auto">
      {/* <h3 className="text-white">File Upload</h3> */}
      {user?.feedbackTypes?.length > 0 ? (
        <div className="self-center my-4">
          <h2 className="text-lg">Select Feedback Type</h2>
          <div className="grid grid-cols-4 mt-3 justify-around items-center">
            {user?.feedbackTypes?.map((type, index) => (
              <label className="uppercase col-span-2" key={index}>
                <input
                  type="radio"
                  name="feedbackType"
                  value={type}
                  checked={type === feedbackType}
                  className="mr-2"
                  onClick={() => setFeedbackType(type)}
                />
                {type}
              </label>
            ))}
          </div>
        </div>
      ) : null}

      {selectedFile && (
        <p className="text-white mt-7">
          {" "}
          Selected File:{" "}
          <span className="font-semibold">{selectedFile?.name}</span>
        </p>
      )}
      <input
        style={{ display: "none" }}
        id="file-upload"
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept=".xlsx,.csv"
      />
      <label className="mt-4" htmlFor="file-upload">
        <Button variant="contained" component="span" color="primary">
          Choose File
        </Button>
      </label>
      {isLoading && <Loader />}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 5,
        }}
      >
        <button onClick={handleCancel} className="btn-danger mx-2">
          Cancel
        </button>
        <button
          onClick={() => {
            handleFileUpload();
          }}
          disabled={!selectedFile}
          className="btn-primary mx-2 disabled:cursor-not-allowed disabled:bg-gray-400"
        >
          Upload
        </button>
      </Box>
      {alertOpen && (
        <p
          style={{
            marginTop: 14,
            color: severity === "error" ? "red" : "green",
          }}
        >
          {alertMessage}
        </p>
      )}
    </div>
  );
};

export default FileUpload;
