import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import { useAuth } from "./components/AuthProvider";
import AddCustomers from "./pages/AddCustomers";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import { RecoilRoot, useRecoilState } from "recoil";
import BlogDetails from "./pages/BlogDetails";
import { userData } from "./recoil/atom/userData";
import { API_DOMAIN } from "./utils/constants";
import axios from "axios";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { ToastContainer } from "react-toastify";

function App() {
  const { isAuthenticated, logout } = useAuth();
  const [userDataDetails, setUserDataDetails] = useRecoilState(userData);
  const navigate = useNavigate();
  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(`${API_DOMAIN}/user`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setUserDataDetails(response.data);
    } catch (err) {
      logout(navigate("/"));
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route
          path="/dashboard/sales"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/enquiry"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-customer"
          element={
            <ProtectedRoute>
              <AddCustomers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/blogs/:id"
          element={
            <RecoilRoot>
              <BlogDetails />
            </RecoilRoot>
          }
        />
        <Route
          path="/blogs"
          element={
            <RecoilRoot>
              <Blog />
            </RecoilRoot>
          }
        />

        <Route
          path="*"
          element={
            isAuthenticated ? (
              <Navigate to="/dashboard/sales" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        // transition={Bounce}
      />
    </>
  );
}

export default App;
