import axios from "axios";
import { API_DOMAIN } from "../utils/constants";

export const uploadReport = async (category, jsonData, feedbackType) => {
  await axios.post(
    `${API_DOMAIN}/upload-report?category=${category}&feedbackType=${feedbackType}`,
    {
      data: jsonData,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};
