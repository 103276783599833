import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import * as React from "react";

export const TableComponent = ({ data }) => {
  const [page, setPage] = React.useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const allKeys = Array.from(new Set(data.flatMap((row) => Object.keys(row))));
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          border: "1px solid black",
          borderRadius: "4px",
          maxHeight: "600px",
          overflow: "auto",
          marginBottom: "8px",
          color: "white",
          fontFamily: "Montserrat, serif !important",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {allKeys.map((column, index) => (
                <TableCell
                  key={index}
                  sx={{
                    backgroundColor: "#7e22ce",
                    color: "#ffffff",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, serif !important",
                  }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * 15, page * 15 + 15).map((item, index) => (
              <TableRow key={index}>
                {allKeys.map((key) => (
                  <TableCell
                    key={key}
                    sx={{
                      backgroundColor: "#7e22ce",
                      color: "#ffffff",
                      fontWeight: "bold",
                      fontFamily: "Montserrat, serif !important",
                      textTransform: "capitalize",
                    }}
                  >
                    {item[key] !== undefined ? item[key] : "NA"}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[]}
        sx={{
          // "& .MuiTablePagination-toolbar": { backgroundColor: "#f5f5f5" },
          "& .MuiTablePagination-selectLabel, & .MuiTablePagination-input": {
            fontSize: "14px",
            color: "white",
          },
          "& .MuiTablePagination-actions button": { color: "#fff" },
          "& .MuiTablePagination-displayedRows": {
            fontSize: "14px",
            color: "white", // Change color
            fontWeight: "bold",
          },
        }}
        component="div"
        count={data.length}
        rowsPerPage={15}
        page={page}
        onPageChange={handleChangePage}
      />
    </>
  );
};
