import { overallFeedback } from "../constants/constants";
import { getMapping } from "./getMapping";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const downloadExcel = (json, name) => {
  const worksheet = XLSX.utils.json_to_sheet(json);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Table Data");

  // Convert to Excel buffer
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  // Save the file
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `${name}.xlsx`);
};
