import * as XLSX from "xlsx";
import Papa from "papaparse";
import { uploadReport } from "../repo/uploadReport";
import { fourWheelerSales, twoWheelerSales } from "../constants/constants";
import { toast } from "react-toastify";
import { userData } from "../recoil/atom/userData";
import { useRecoilValue } from "recoil";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useFileUploadService = () => {
  const user = useRecoilValue(userData);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [feedbackType, setFeedbackType] = useState("sales");

  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(()=>{
    setFeedbackType(user.feedbackTypes?.[0] ?? "sales")
  },[user])
  const handleUploadCompletion = (message, severity) => {
    setAlertMessage(message);
    setSeverity(severity);
    setAlertOpen(true);
    setSelectedFile(null);
    resetFileInput();
    setIsLoading(false);
    console.log("handleCompletion");
    if (severity === "error") {
      toast.error(message);
    } else {
      toast.success(message);
      navigate("/dashboard");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const uploadFile = async () => {
    setIsLoading(true);
    if (!selectedFile) return;
    const fileType = selectedFile.type;

    if (
      fileType ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      await readExcelFile(selectedFile);
    } else if (fileType === "text/csv") await readCsvFile();
    else {
      setAlertMessage("Unsupported file type.");
      setSeverity("error");
      setAlertOpen(true);
      resetFileInput();
      toast.error("Unsupported file type.");
      return;
    }
  };

  const readCsvFile = async () => {
    const reader = new FileReader();

    if (
      user?.category === twoWheelerSales ||
      user?.category === fourWheelerSales
    ) {
      reader.onload = async ({ target }) => {
        try {
          const csv = target.result;
          Papa.parse(csv, {
            skipEmptyLines: true,
            complete: async (result) => {
              const headers = result.data[0];
              const processedData = result.data.map((row) =>
                headers.reduce((acc, header, index) => {
                  acc[header] = row[index] || "";
                  return acc;
                }, {})
              );
              await handleSheetData(processedData);
            },
          });
        } catch (error) {
          console.error("CSV Upload Error:", error);
          handleUploadCompletion("Failed to read file", "error");
        }
      };

      reader.readAsText(selectedFile);
    } else {
      reader.onload = async ({ target }) => {
        try {
          const csv = target.result;
          Papa.parse(csv, {
            skipEmptyLines: true,
            complete: async (result) => {
              const headers = result.data[3];
              const processedData = result.data.slice(4).map((row) =>
                headers.reduce((acc, header, index) => {
                  acc[header] = row[index] || "";
                  return acc;
                }, {})
              );
              await handleSheetData(processedData);
            },
          });
        } catch (error) {
          console.error("CSV Upload Error:", error);
          handleUploadCompletion("Failed to read file", "error");
        }
      };

      reader.readAsText(selectedFile);
    }
  };

  const readExcelFile = async () => {
    try {
      console.log("reading excel file");
      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const arrayBuffer = event.target.result;
          const workbook = XLSX.read(arrayBuffer, {
            type: "array",
            cellDates: true,
          });
          const sheetName = workbook.SheetNames[0];
          const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

          await handleSheetData(jsonData);
        } catch (error) {
          console.error("Excel Upload Error:", error);
          handleUploadCompletion("Failed to read file", "error");
        }
      };

      reader.readAsArrayBuffer(selectedFile);
    } catch (error) {
      console.error("Excel Upload Error:", error);
      handleUploadCompletion("Failed to read file", "error");
    }
  };

  const handleSheetData = async (jsonData) => {
    try {
      await uploadReport(user?.category, jsonData, feedbackType);

      handleUploadCompletion("File uploaded successfully!", "success");
    } catch (error) {
      handleUploadCompletion("Error uploading file", "error");
    }
  };

  const resetFileInput = () => {
    // setAlertMessage("");
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // or ''
    }
  };

  return {
    uploadFile,
    selectedFile,
    handleFileChange,
    isLoading,
    severity,
    alertOpen,
    alertMessage,
    resetFileInput,
    feedbackType,
    setFeedbackType,
    fileInputRef,
  };
};
