export const twoWheelerSales = "twoWheelerSales";
export const fourWheelerSales = "fourWheelerSales";
export const restaurant = "restaurant";
export const concernsEnum = {
  0: "car-delivery-delay",
  1: "sales-person",
  2: "deal-commitment-failure",
  3: "delivery-experience",
  4: "documents-not-received",
  5: "accessories",
  6: "refund-pending",
  7: "others",
  8: "professionalism",
  9: "lack-of-knowledge",
  10: "accessories-forced",
  11: "extended-warranty-forced",
  12: "timely-updates",
  13: "long-waiting-time",
  14: "dirty-car",
  15: "scratches-dents",
  16: "staff-enthusiasm",
  17: "documents-not-explained",
  18: "scheme-document",
  19: "promised-accessories",
  20: "overcharging",
  21: "delay-1-week",
  22: "delay-1-3-weeks",
  23: "delay-1-month",
  24: "not-listed",
  25: "vehicle-rc",
  26: "vehicle-invoice",
  27: "insurance",
  28: "extended-warranty",
  29: "other-document",
  30: "false-commitment",
  31: "accessories-pending",
  32: "fitment-issue",
  33: "quality",
  34: "car-performance",
  35: "finance",
  36: "welcome-experience",
  37: "company",
  38: "no-specific",
  39: "sales",
  40: "purchase",
  41: "product",
  42: "test-ride",
  43: "showroom",
  44: "showroom-access",
  45: "color",
  46: "poor-product",
  47: "response",
  48: "transparency",
  49: "demo",
  50: "no-updates",
  51: "no-brochure",
  52: "bike-dent",
  53: "documents",
  54: "staff-enthusiasm",
  55: "delight",
  56: "slots",
  57: "booking-ease",
  58: "quality",
  59: "route",
  60: "length",
  61: "billing-ease",
  62: "delivery-time",
  63: "rto",
  64: "insurance",
  65: "special-chasis",
  66: "seating",
  67: "parking",
  68: "cleanliness",
  69: "beverage",
  70: "distance",
  71: "connectivity",
  72: "locate",
  73: "finance",
  74: "accessories-availability",
  75: "welcome",
  76: "accessories-quality",
  77: "accessories-fitting",
  78: "scheme-discount",
  79: "overcharging",
  80: "accessories-commitment",
  81: "preferred-model-na",
  82: "no-greeting",
  83: "no-attendance",
  84: "receptionist",
};

export const overallFeedback = {
  0: "zero",
  1: "poor",
  2: "average",
  3: "excellent",
};

export const sales = "Sales";
export const enquiry = "Enquiry";
export const tabs = [
  { key: sales, label: sales },
  { key: enquiry, label: enquiry },
];
