import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, Card, Divider, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { concernsEnum, overallFeedback } from "../constants/constants";
import { userData } from "../recoil/atom/userData";
import { downloadExcel } from "../utils/downloadExcel";
import { getMapping } from "../utils/getMapping";
import { TableComponent } from "./TableComponent";
const ExportableTable = ({ npsData, noFeedback, failed }) => {
  const user = useRecoilValue(userData);
  const selectedFeedbackType = localStorage.getItem("selectedFeedbackType");
  const [tableData, setTableData] = useState([]);
  const handleExport = () => {
    const json = npsData.map((item) => {
      let result = {};
      result["NPS"] = getMapping(overallFeedback, item["feedback"]["overall"]);
      result["Concern"] = getMapping(concernsEnum, item["feedback"]["concern"]);
      result["Sub Concern"] = getMapping(
        concernsEnum,
        item["feedback"]["sub-concern"]
      );
      result["Feedback Text"] = item?.feedback?.feedbackText ?? "-";
      user?.exportTableMap?.[selectedFeedbackType]?.forEach((column) => {
        const value = getValueFromPath(item, column.value);
        result[column.title] = value;
      });
      return result;
    });
    downloadExcel(json, "nps_data");
  };
  const getValueFromPath = (item, path) => {
    let value;
    const [objName, ...keys] = path.split(".");
    if (objName === "user") {
      value = item[keys.join(".")];
    } else {
      value = item?.metadata[keys.join(".")];
    }
    if (path.includes("Date")) {
      const format = moment(value).format("DD-MM-YYYY");
      value = format === "Invalid date" ? value : format;
    }
    return value;
  };

  useEffect(() => {
    const transformedData = npsData.map((row) => {
      let newRow = {};
      newRow["NPS"] = getMapping(overallFeedback, row["feedback"]["overall"]);
      newRow["Concern"] = getMapping(concernsEnum, row["feedback"]["concern"]);
      newRow["Sub Concern"] = getMapping(
        concernsEnum,
        row["feedback"]["sub-concern"]
      );

      user?.tableMap?.[selectedFeedbackType]?.forEach((column) => {
        const value = getValueFromPath(row, column.value);
        newRow[column.title] = value;
      });
      return newRow;
    });

    setTableData(transformedData);
  }, [npsData, selectedFeedbackType]);

  const exportDataForNoFeedback = () => {
    const columnMappingNoFeedback = {
      "Customer Name": { key: "name" },
      Phone: { key: "phone" },
      "Message Sent On": { key: "created", type: "date" },
    };

    const transformedData =
      noFeedback &&
      noFeedback?.map((row) => {
        let newRow = {};
        Object.entries(columnMappingNoFeedback).forEach(([colName, key]) => {
          let value = row[key["key"]] || "NA";

          if (key["type"] && key["type"] === "date" && value !== "NA") {
            value = moment(value).format("DD-MM-YYYY");
          }
          newRow[colName] = value;
        });
        return newRow;
      });
    downloadExcel(transformedData, "no_feedback_received_data");
  };

  const exportDataForFailed = () => {
    const columnMappingFailed = {
      "Customer Name": { key: "name" },
      Phone: { key: "phone" },
      "Failure Reason": { key: "failureReason" },
      "Other Data": { key: "metadata", type: "object" },
    };

    const failedExcelData = failed?.map((row) => {
      let newRow = {};
      Object.entries(columnMappingFailed).forEach(([colName, key]) => {
        let value = row[key["key"]] || "NA";

        if (key["type"] && key["type"] === "date" && value !== "NA") {
          value = moment(value).format("DD-MM-YYYY");
        }

        if (key["type"] && key["type"] === "object") {
          console.log("value", value);
          let modValue = "";
          Object.keys(value).forEach((k) => {
            modValue = modValue.concat(`${k} : ${value[k]} \n | \n`);
          });
          value = modValue;
        }
        newRow[colName] = value;
      });
      return newRow;
    });
    downloadExcel(failedExcelData, "message_delivery_failed_data");
  };

  return (
    <div className="dark">
      <Card
        variant="outlined"
        sx={{
          boxShadow: 3,
          width: "100%",
          p: "15px",
          background: "#1d2637",
          color: "white",
          fontFamily: "Montserrat, serif !important",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          alignSelf={"flex-end"}
          justifyContent="space-between"
          marginBottom={2}
          marginTop={2}
        >
          <Typography
            variant="overline"
            gutterBottom
            sx={{ color: "white", fontFamily: "Montserrat, serif !important" }}
          >
            NPS Data
          </Typography>
          {user?.lastUploaded && (
            <Typography
              variant="overline"
              gutterBottom
              sx={{
                color: "white",
                fontFamily: "Montserrat, serif !important",
                textAlign: "right",
              }}
            >
              Last Uploaded Date : {user?.lastUploaded}
            </Typography>
          )}
        </Box>

        <Divider />
        <Box
          display="flex"
          alignItems="center"
          alignSelf={"flex-end"}
          justifyContent="start"
          marginBottom={2}
          marginTop={2}
        >
          <Button
            variant="outlined"
            color="#e62552"
            onClick={handleExport}
            startIcon={<DownloadIcon />}
            sx={{ fontFamily: "Montserrat, serif !important" }}
          >
            Export NPS Data
          </Button>

          {/* {noFeedback && noFeedback?.length > 0 && <Button
            variant="outlined"
            color="#e62552"
            onClick={exportDataForNoFeedback}
            startIcon={<DownloadIcon />}
            sx={{ fontFamily: "Montserrat, serif !important" }}
          >
            Export No Feedback Data
          </Button>} */}
          {failed && failed?.length > 0 && (
            <Button
              variant="outlined"
              color="#e62552"
              onClick={exportDataForFailed}
              startIcon={<DownloadIcon />}
              sx={{
                fontFamily: "Montserrat, serif !important",
                marginLeft: 10,
              }}
            >
              Delivery Failed Data
            </Button>
          )}
        </Box>
        {user?.tableMap?.[selectedFeedbackType] &&
        user?.tableMap?.[selectedFeedbackType]?.length > 0 ? (
          <TableComponent data={tableData} />
        ) : (
          <Typography>Contact Admin to Configure Table Data</Typography>
        )}
      </Card>
    </div>
  );
};

export default ExportableTable;
