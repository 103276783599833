import { useNavigate } from "react-router-dom";
import HeroImage from "../assets/images/bizecho-homepage.png";

export const NoFeedBackYet = () =>{
    const navigate = useNavigate()
    return (
        <div className="min-h-screen bg-gray-900 flex justify-center">
            <div className="py-16 items-center flex flex-col">
            <img src={HeroImage} className="h-56 w-56 object-contain" alt="Hero" />
            <p className="text-white text-xl">You have not received any feedback yet. Try uploading customer file?</p>
            <button  onClick={() => navigate("/add-customer")} className="btn-primary mt-4">Upload Customer File</button>
            </div>
        </div>
    )
}