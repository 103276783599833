import { Box, Grid2 } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NoFeedBackYet } from "../components/NoFeedbackYet";

import {
  concernsEnum,
  overallFeedback,
  sales,
  twoWheelerSales,
} from "../constants/constants";
import { getMapping } from "../utils/getMapping";
import ConcernCategoryBarChart from "./ConcernCategoryBarChart";
import DSENPSBarChart from "./DSENPSBarChart";
import FeedbackPieChart from "./FeedbackPieChart";
import ModelNPSBarChart from "./ModelNPSBarChart";
import MonthwiseFeedbackChart from "./MonthwiseFeedbackChart";
import NPSScoreWidget from "./NPSScoreWidget";
import SubConcernCategoryBarChart from "./SubConcernCategoryBarChart ";
import TLNPSBarChart from "./TLNPSBarChart";
import UserDataTable from "./UserDataTable";
import { DatePicker } from "./DatePicker";
import { useGetNPSData } from "../hooks/useGetNPSData";
import { DSEWiseNPSTable } from "./DSEWiseNPSTable";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userData } from "../recoil/atom/userData";

const FeedbackChart = () => {
  const [windowUrl, setWindowUrl] = useState();

  const {
    fetchNPSDetails,
    filterDataForDate,
    npsDetails,
    npsData,
    noFeedback,
    failed,
    category,
    loading,
  } = useGetNPSData();
  const navigate = useNavigate();
  const user = useRecoilValue(userData);
  const [npsScore, setNpsScore] = useState(0);
  const [concernData, setConcernData] = useState(null);
  const [subConcernData, setSubConcernData] = useState(null);
  const [businessChartData, setBusinessChartData] = useState(null);
  const [monthWiseConsumedMessages, setMonthWiseConsumedMessages] = useState(0);
  const [responseRatio, setResponseRatio] = useState("");
  const [filterDate, setFilterDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [dseWiseNPS, setDSEWiseNPS] = useState([]);

  const calculateResponseRatio = () => {
    const feedbackDataCount = npsData?.length ?? 0;
    const noFeedbackDataCount = noFeedback?.length ?? 0;
    const total =
      feedbackDataCount + noFeedbackDataCount > 0
        ? feedbackDataCount + noFeedbackDataCount
        : 1;
    setResponseRatio(Math.ceil((feedbackDataCount / total) * 100));
  };

  const calculateMonthWiseConsumedMessages = () => {
    const getCurrentMonth = new Date().getMonth();
    let messageConsumed = 0;

    const mergedFeedbackNoFeedbackArray = npsData.concat(noFeedback);
    mergedFeedbackNoFeedbackArray.forEach((data) => {
      const updatedDate = new Date(data?.created).getMonth();
      if (getCurrentMonth === updatedDate) {
        messageConsumed++;
      }
    });
    setMonthWiseConsumedMessages(messageConsumed);
  };
  const calculateBusinessWiseNPS = () => {
    const updatedBusinessNPSData = npsData.map((item) => ({
      feedback: getMapping(overallFeedback, item.feedback.overall),
      model: item.metadata.model,
      // updatedDate: new Date(item?.metadata?.updated),
      updatedDate: item?.updated,
      invoice_date: new Date(item?.invoiceDate).toLocaleString("en-US", {
        month: "short",
        year: "numeric",
      }),
      DSE: item.metadata.dse,
      // model_category: item?.metadata?.modelCategory,
      customer_name: item.name,
      phone: item.phone,
      concern: getMapping(concernsEnum, item.feedback.concern),
      subconcern: getMapping(concernsEnum, item.feedback?.["sub-concern"]),
      team_lead: item?.metadata?.teamLead,
      message_status: item?.messageStatus,
    }));
    setBusinessChartData(updatedBusinessNPSData);
  };

  const calculateNPSScore = () => {
    const total = npsData.length;
    const excellentCount = npsData.filter((item) => {
      return (
        getMapping(overallFeedback, item.feedback?.overall) === "excellent"
      );
    }).length;

    const poorCount = npsData.filter((item) => {
      return (
        getMapping(overallFeedback, item.feedback?.overall) === "poor" ||
        getMapping(overallFeedback, item.feedback?.overall) === "zero"
      );
    }).length;

    const percentage = () => {
      return (excellentCount - poorCount) / total;
    };
    setNpsScore(total === 0 ? 0 : percentage().toFixed(2) * 100);
  };

  const countLowRatingReason = () => {
    const filteredData = npsData.filter((d) => d.feedback["concern"]);
    const reasonCount = filteredData.reduce((acc, obj) => {
      let reasonNumber = obj.feedback.concern;
      let reason = getMapping(concernsEnum, reasonNumber);
      if (reason) {
        reason = reason
          .replace(/_/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase());
        acc[reason] = (acc[reason] || 0) + 1;
      }
      return acc;
    }, {});

    const resultArray = Object.entries(reasonCount).map(([reason, value]) => ({
      reason,
      value,
    }));
    const top5Concern = resultArray
      .sort((a, b) => parseInt(a.value) - parseInt(b.value))
      .slice(0, 5);

    setConcernData(top5Concern);
  };

  const countSubConcern = () => {
    const filteredData = npsData.filter((d) => d.feedback["sub-concern"]);
    const reasonCount = filteredData.reduce((acc, obj) => {
      let subReasonNumber = obj.feedback?.["sub-concern"];
      let reason = getMapping(concernsEnum, subReasonNumber);
      if (reason) {
        reason = reason
          .replace(/_/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase());
        acc[reason] = (acc[reason] || 0) + 1;
      }
      return acc;
    }, {});

    const resultArray = Object.entries(reasonCount).map(([reason, value]) => ({
      reason,
      value,
    }));

    const top5SubConcern = resultArray
      .sort((a, b) => parseInt(a.value) - parseInt(b.value))
      .slice(0, 5);

    setSubConcernData(top5SubConcern);
  };

  useEffect(() => {
    calculateNPSScore();
    countLowRatingReason();
    countSubConcern();
    calculateBusinessWiseNPS();
    calculateMonthWiseConsumedMessages();
    calculateResponseRatio();
  }, [npsData]);

  useEffect(() => {
    setWindowUrl(window?.location?.pathname?.split("/dashboard/")?.[1]);
    fetchNPSDetails(window?.location?.pathname?.split("/dashboard/")?.[1]);
    localStorage.setItem(
      "selectedFeedbackType",
      window?.location?.pathname?.split("/dashboard/")?.[1]
    );
  }, [window.location.pathname]);

  useEffect(() => {
    filterDataForDate(filterDate);
  }, [filterDate]);

  return loading ? (
    <p>Loading...</p>
  ) : (
    <div>
      {npsDetails ? (
        <div className="bg-white dark:bg-gray-900 py-2 min-h-[100vh]">
          <Box
            sx={{
              flexGrow: 1,
              fontFamily: "Roboto, Arial, sans-serif", // Replace with your font.
            }}
          >
            <Grid2 container spacing={6} sx={{ m: "20px" }}>
              <Grid2 size={{ xs: 12, md: 12 }} md={{ color: "white" }}>
                <div className="flex" style={{ alignItems: "anchor-center" }}>
                  <div className="flex-initial w-64 text-white">
                    <p>Filter Data according to Date : </p>
                  </div>
                  <div className="flex-initial w-64" style={{ width: "25%" }}>
                    <DatePicker
                      setFilterDate={setFilterDate}
                      filterDate={filterDate}
                    />
                  </div>{" "}
                  {user?.feedbackTypes?.length > 1 && (
                    <div className="text-end flex-1 w-64">
                      <span className="text-white">
                        <button
                          onClick={() => {
                            windowUrl === sales.toLowerCase()
                              ? navigate("/dashboard/enquiry")
                              : navigate("/dashboard/sales");
                          }}
                          className="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                        >
                          {windowUrl === sales.toLowerCase()
                            ? "Switch to Enquiry Dashboard"
                            : "Switch to Sales Dashboard"}
                        </button>
                      </span>{" "}
                    </div>
                  )}
                </div>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }} md={{ color: "white" }}>
                {npsScore !== null && (
                  <NPSScoreWidget
                    score={npsScore}
                    totalResponders={npsData.length}
                    monthWiseConsumedMessages={monthWiseConsumedMessages}
                    responseRatio={responseRatio}
                  />
                )}
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }} md={{ color: "white" }}>
                {npsData.length > 0 && (
                  <FeedbackPieChart data={businessChartData} />
                )}
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                {npsData.length > 0 && (
                  <ModelNPSBarChart data={businessChartData} />
                )}
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                {npsData.length > 0 && (
                  <DSENPSBarChart
                    data={businessChartData}
                    setDSEWiseNPS={setDSEWiseNPS}
                  />
                )}
              </Grid2>
              {npsData.length > 0 && category !== twoWheelerSales && (
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <TLNPSBarChart data={businessChartData} />
                </Grid2>
              )}

              <Grid2 size={{ xs: 12, md: 6 }}>
                {npsData.length > 0 && (
                  <MonthwiseFeedbackChart data={businessChartData} />
                )}
              </Grid2>
              <Grid2 size={{ xs: 12, md: 12 }}>
                {npsData.length > 0 && (
                  <ConcernCategoryBarChart concernData={concernData} />
                )}
              </Grid2>
              <Grid2 size={{ xs: 12, md: 12 }}>
                {npsData.length > 0 && (
                  <SubConcernCategoryBarChart subConcernData={subConcernData} />
                )}
              </Grid2>
              <Grid2 size={12}>
                {npsData.length > 0 && (
                  <UserDataTable
                    npsData={npsData}
                    noFeedback={noFeedback}
                    failed={failed}
                  />
                )}
              </Grid2>
              <Grid2 size={12}>
                {dseWiseNPS?.length > 0 && (
                  <DSEWiseNPSTable dseWiseNPS={dseWiseNPS} />
                )}
              </Grid2>
            </Grid2>
          </Box>
        </div>
      ) : (
        <NoFeedBackYet />
      )}
    </div>
  );
};

export default FeedbackChart;
