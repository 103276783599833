import { Card, Divider, Typography, Grid2 } from "@mui/material";
import React, { useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { useRecoilValue } from "recoil";
import { userData } from "../recoil/atom/userData";

const NPSScoreWidget = ({
  score,
  totalResponders,
  monthWiseConsumedMessages,
  responseRatio,
}) => {
  const user = useRecoilValue(userData);

  return (
    <Card
      className="card-admin"
      variant="outlined"
      sx={{
        boxShadow: 3,
        width: "100%",
        height: "320px",
        p: "15px",
        background: "#1d2637",
        color: "white",
        borderRadius: "12px",
        fontFamily: "Montserrat, serif !important",
      }}
    >
      <Typography
        sx={{ fontFamily: "Montserrat, serif !important" }}
        variant="overline"
        gutterBottom
      >
        NPS (Net Promoter Score)
      </Typography>
      <Divider />
      <Typography
        variant="h4"
        gutterBottom
        sx={{ marginTop: "10px", fontFamily: "Montserrat, serif !important" }}
      >
        {Math.floor(score)}
      </Typography>

      <LinearProgress
        variant="buffer"
        value={score}
        valueBuffer={100}
        color="success"
        sx={{
          height: "30px",
          marginTop: "10px",
          backgroundColor: "#e0e0e0",
          color: "000",
          borderRadius: "2rem",
        }}
      />

      <Divider
        sx={{
          border: "1px solid  #ffffff75",
          marginTop: "1.7em",
          marginBottom: "1.7em",
        }}
      />
      {/* <Typography
        style={{ fontFamily: "Montserrat, serif !important" }}
        variant="overline"
        gutterBottom
      >
        Total Responses
      </Typography> */}
      <Divider />
      <Grid2 container spacing={2} sx={{ m: "5px", marginTop: "0px" }}>
        <Grid2 size={{ xs: 12, md: 4 }} md={{ color: "white" }}>
          <Typography
            style={{ fontFamily: "Montserrat, serif !important" }}
            variant="overline"
            gutterBottom
          >
            Total Responses
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              marginTop: "2px",
              fontFamily: "Montserrat, serif !important",
            }}
          >
            {totalResponders}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4 }} md={{ color: "white" }}>
          <Typography
            style={{ fontFamily: "Montserrat, serif !important" }}
            variant="overline"
            gutterBottom
          >
            Response Ratio
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              marginTop: "2px",
              fontFamily: "Montserrat, serif !important",
            }}
          >
            {responseRatio}%
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4 }} md={{ color: "white" }}>
          <Typography
            style={{ fontFamily: "Montserrat, serif !important" }}
            variant="overline"
            gutterBottom
          >
            Monthly Message Counter
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              marginTop: "2px",
              fontFamily: "Montserrat, serif !important",
            }}
          >
            {monthWiseConsumedMessages}/{user?.monthlyCounter}
          </Typography>
        </Grid2>
      </Grid2>
    </Card>
  );
};

export default NPSScoreWidget;
