import { Card, Divider, Typography, useTheme } from "@mui/material";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Label,
} from "recharts";

const SubConcernCategoryBarChart = ({ subConcernData }) => {
  const theme = useTheme();

  const CustomTick = (props) => {
    const { x, y, payload } = props;
    const words = payload.value.split(" ");
    const lines = [];
    let line = "";

    words.forEach((word) => {
      if ((line + word).length > 10) {
        lines.push(line);
        line = word;
      } else {
        line = line ? `${line} ${word}` : word;
      }
    });

    lines.push(line);

    return (
      <g transform={`translate(${x},${y + 10})`}>
        {lines.map((line, index) => (
          <text
            key={index}
            x={0}
            y={index * 12}
            textAnchor="middle"
            fill="white"
            fontSize={12}
          >
            {line}
          </text>
        ))}
      </g>
    );
  };

  return (
    <Card
      variant="outlined"
      sx={{
        boxShadow: 3,
        width: "100%",
        height: "350px",
        p: "25px",
        background: "#1d2637",
        color: "white",
        borderRadius: "12px",
        fontFamily: "Montserrat, serif !important",
      }}
    >
      <Typography
        sx={{ fontFamily: "Montserrat, serif !important" }}
        variant="overline"
        gutterBottom
      >
        Sub Concern Category
      </Typography>
      <Divider />
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={subConcernData}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 50,
          }}
          width={150}
          height={40}
        >
          <XAxis dataKey="reason" tick={<CustomTick />}>
            <Label
              value="Sub Concern"
              offset={-35}
              position="insideBottom"
              style={{ fill: "white", marginTop: 10 }}
            />
          </XAxis>
          <YAxis>
            <Label
              value="Count"
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: "middle", fill: "white" }}
            />
          </YAxis>
          <Tooltip cursor={{ fill: "transparent" }} />
          <Bar dataKey="value" fill="#FB9C0C">
            <LabelList
              dataKey="value"
              position="top"
              style={{
                ...theme.typography.caption,
                fill: "white",
              }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default SubConcernCategoryBarChart;
