import { Button } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { FaFilter } from "react-icons/fa";

export const DatePicker = ({ setFilterDate, filterDate }) => {
  const handleValueChange = (newValue) => {
    setFilterDate(newValue);
  };

  return (
    <Datepicker
      value={filterDate}
      showShortcuts={true}
      onChange={handleValueChange}
    />
  );
};
