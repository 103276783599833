import React from "react";
import Header from "./Header";

const PrivacyPolicy = () => {
  return (
    <div className="">
      <Header />

      <div className="bg-gray-900 text-white min-h-screen p-6">
        <div className="w-full mx-auto bg-gray-800 p-8 rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold mb-6 text-center">
            Privacy Policy
          </h1>
          <hr className="font-white w-50 text-center pb-5" />
          <section className="mb-6">
            <h2 className="text-2xl font-semibold">1. Introduction</h2>
            <p className="mt-2">
              Welcome to Bizecho Tech! At Bizecho Tech, we prioritize the
              security and confidentiality of the data we collect on behalf of
              our business clients. We understand that the data belongs to you
              and your customers, and we are committed to protecting it with the
              highest standards of data security and privacy.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold">
              2. Information We Collect
            </h2>
            <ul className="list-disc pl-6 mt-2">
              <li>
                Customer Feedback Data: Feedback, ratings, and comments shared
                via WhatsApp.
              </li>
              <li>
                Customer Identifiers: Name, contact details, product purchased.
              </li>
              <li>
                Business Information: Business name, industry, and contact
                details.
              </li>
              <li>Usage Data: Dashboard interactions and analytics reports.</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold">
              3. Data Ownership and Responsibility
            </h2>
            <p className="mt-2">
              Your customer data belongs to you. Bizecho Tech acts as a
              processor and does not contact customers directly.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold">
              4. How We Use Your Customer Data
            </h2>
            <ul className="list-disc pl-6 mt-2">
              <li>Deliver and enhance the feedback collection service.</li>
              <li>
                Provide insights and analytics for customer experience
                improvement.
              </li>
              <li>Ensure data security and prevent unauthorized access.</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold">
              5. Data Security and Confidentiality
            </h2>
            <p className="mt-2">
              We implement encryption, access controls, and regular updates to
              protect data security.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold">
              6. Data Sharing and Confidentiality
            </h2>
            <p className="mt-2">
              We do not sell or share data with third parties, except under
              strict confidentiality agreements or legal requirements.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold">
              7. Data Retention and Deletion
            </h2>
            <p className="mt-2">
              We retain data only as long as necessary and allow deletion
              requests at any time.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold">
              8. Compliance with Regulations
            </h2>
            <p className="mt-2">
              Bizecho Tech complies with GDPR, the Indian IT Act, and other
              applicable laws.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold">
              9. Your Rights and Responsibilities
            </h2>
            <p className="mt-2">
              As a B2B client, you are the Data Controller, and Bizecho Tech is
              the Data Processor.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold">
              10. Changes to this Privacy Policy
            </h2>
            <p className="mt-2">
              We may update this Privacy Policy periodically. Significant
              changes will be posted on our website.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold">11. Contact Us</h2>
            <p className="mt-2">
              If you have any questions, please contact us at:
            </p>
            <p className="mt-2">
              Email:{" "}
              <a
                href="mailto:contact@Bizecho Tech.co.in"
                className="text-blue-400"
              >
                contact@Bizecho Tech.co.in
              </a>
            </p>
            <p className="mt-2">
              Website:{" "}
              <a href="https://Bizecho Tech.co.in/" className="text-blue-400">
                https://Bizecho Tech.co.in/
              </a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
