import axios from "axios";
import { useEffect, useState } from "react";
import { API_DOMAIN } from "../utils/constants";

export const useGetData = (url) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const getData = async () => {
    try {
      // const response = await axios.get(`${API_DOMAIN}/nps-details`, {
      const response = await axios.get(`${API_DOMAIN}/${url}`, {});
      setData(response?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [url]);

  return { getData, data, loading };
};
