import { useEffect, useState } from "react";
import axios from "axios";
import { API_DOMAIN } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/AuthProvider";
import { userData } from "../recoil/atom/userData";
import { useRecoilValue } from "recoil";
import { sales } from "../constants/constants";

export const useGetNPSData = (windowUrl) => {
  const user = useRecoilValue(userData);
  const [npsDetails, setNpsDetails] = useState([]);
  const [npsData, setNpsData] = useState([]);
  const [noFeedback, setNoFeedback] = useState([]);
  const [failed, setFailed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState("");
  const { logout } = useAuth();
  const navigate = useNavigate();
  const fetchNPSDetails = async (feedbackType) => {
    try {
      const response = await axios.get(
        `${API_DOMAIN}/nps-details?feedbackType=${feedbackType.toLowerCase()}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setNpsDetails(response.data);
      setNpsData(response.data?.feedback ?? []);
      setNoFeedback(response.data?.noFeedback ?? []);
      setFailed(response.data?.failed ?? []);
    } catch (err) {
      console.error(err);
      logout(navigate("/"));
    } finally {
      setLoading(false);
    }
  };
  const filterDataForDate = (filterDate) => {
    console.log("filterDate", filterDate);
    if (filterDate?.startDate === null && filterDate?.endDate === null) {
      setNpsData(npsDetails?.feedback ?? []);
      setNoFeedback(npsDetails?.noFeedback ?? []);
      setFailed(npsDetails.data?.failed ?? []);
    } else {
      let filteredFeedbackData = npsDetails?.feedback?.filter((feedback) => {
        let date = new Date(feedback["updated"]);
        return date >= filterDate.startDate && date <= filterDate.endDate;
      });

      let filteredNoFeedbackData = npsDetails?.noFeedback?.filter(
        (feedback) => {
          let date = new Date(feedback["updated"]);
          return date >= filterDate.startDate && date <= filterDate.endDate;
        }
      );

      let filteredFailedData = npsDetails?.failed?.filter((feedback) => {
        let date = new Date(feedback["updated"]);
        return date >= filterDate.startDate && date <= filterDate.endDate;
      });

      setNpsData(filteredFeedbackData ?? []);
      setNoFeedback(filteredNoFeedbackData ?? []);
      setFailed(filteredFailedData ?? []);
    }
  };
  useEffect(() => {
    fetchNPSDetails(sales);
    setCategory(user?.category);
  }, []);

  return {
    fetchNPSDetails,
    npsDetails,
    filterDataForDate,
    npsData,
    noFeedback,
    failed,
    category,
    loading,
  };
};
