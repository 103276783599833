import React, { useEffect, useState } from "react";

import { useAuth } from "../components/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userData } from "../recoil/atom/userData";

function Header() {
  const { logout, isAuthenticated } = useAuth();
  const user = useRecoilValue(userData);
  const navigate = useNavigate();
  // State to manage the mobile menu visibility
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        const button = document.getElementById(
          `${location.hash.slice(1)}-button`
        );
        // if (button) {
        //   button.classList.remove("text-gray-700");
        //   button.classList.add("text-white");
        // }
      }
    }
  }, [location]);
  return (
    <header className="w-full dark">
      <nav className="bg-white border-gray-200 py-2.5 dark:bg-gray-900">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
          {/* Logo */}
          <button onClick={() => navigate("/")} className="flex items-center">
            {/* <img src={Logo} className="h-6 mr-3 sm:h-9" alt="BizEcho Logo" /> */}
            <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
              Bizecho
            </span>
          </button>
          <div className="flex items-center lg:order-2 dark">
            {isAuthenticated && (
              <p className="text-white pe-3">Welcome, {user?.businessName} </p>
            )}
            {isAuthenticated ? (
              <button
                onClick={() => logout(navigate("/"))}
                href="#home"
                className="block py-2 pl-3 pr-4  bg-purple-700 rounded  dark:text-white"
              >
                Logout
              </button>
            ) : (
              window.location.pathname !== "/login" && (
                <button
                  onClick={() => navigate("/login")}
                  className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
                >
                  Login
                </button>
              )
            )}

            <button
              onClick={toggleMobileMenu}
              type="button"
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-expanded={isMobileMenuOpen}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>

          {/* Navigation Links */}
          <div
            className={`items-center justify-between ${
              isMobileMenuOpen ? "block" : "hidden"
            } w-full lg:flex lg:w-auto lg:order-1`}
          >
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
              {isAuthenticated && (
                <>
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="block py-2 pl-3 pr-4 text-white bg-purple-700 rounded lg:bg-transparent lg:text-purple-700 lg:p-0 dark:text-white"
                    >
                      Home
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/blogs")}
                      className="block py-2 pl-3 pr-4 text-white bg-purple-700 rounded lg:bg-transparent lg:text-purple-700 lg:p-0 dark:text-white"
                    >
                      Blog
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/dashboard/sales")}
                      className="block py-2 pl-3 pr-4 text-white bg-purple-700 rounded lg:bg-transparent lg:text-purple-700 lg:p-0 dark:text-white"
                    >
                      Dashboard
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/add-customer")}
                      className="block py-2 pl-3 pr-4 text-white bg-purple-700 rounded lg:bg-transparent lg:text-purple-700 lg:p-0 dark:text-white"
                    >
                      Add Customer
                    </button>
                  </li>
                </>
              )}

              {!isAuthenticated && window.location.pathname !== "/login" && (
                <>
                  {/* Mobile Menu Button */}

                  <li>
                    <button
                      id="home-button"
                      onClick={() => navigate("/")}
                      className="block py-2 pl-3 pr-4 text-white bg-purple-700 rounded lg:bg-transparent lg:text-purple-700 lg:p-0 dark:text-white"
                    >
                      Home
                    </button>
                  </li>
                  <li>
                    <button
                      id="blog-button"
                      onClick={() => navigate("/blogs")}
                      className="block py-2 pl-3 pr-4 text-white bg-purple-700 rounded lg:bg-transparent lg:text-purple-700 lg:p-0 dark:text-white"
                    >
                      Blog
                    </button>
                  </li>
                  <li>
                    <button
                      id="about-button"
                      onClick={() => navigate("/#about")}
                      className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                    >
                      About Us
                    </button>
                  </li>
                  <li>
                    <button
                      id="services-button"
                      onClick={() => navigate("/#services")}
                      className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                    >
                      Services
                    </button>
                  </li>
                  <li>
                    <button
                      id="contact-button"
                      onClick={() => navigate("/#contact")}
                      className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                    >
                      Contact Us
                    </button>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
