import { Card, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
  Sector,
} from "recharts";

const FeedbackPieChart = ({ data }) => {
  const [feedbackData, setFeedbackData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const COLORS = ["#FB9B0B", "#f9f939", "#fd6b3a"];
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  function calculateMonthlyNPS(responses) {
    const modelNPS = {
      promoters: 0,
      detractors: 0,
      passives: 0,
      total: 0,
    };

    for (const response of responses) {
      if (response.feedback) {
        if (response.feedback === "excellent") {
          modelNPS.promoters++;
        } else if (
          response.feedback === "poor" ||
          response.feedback === "zero"
        ) {
          modelNPS.detractors++;
        } else {
          modelNPS.passives++;
        }
        modelNPS.total++;
      }
    }
    const result = [
      { name: "Promoter", value: modelNPS.promoters },
      { name: "Passive", value: modelNPS.passives },
      { name: "Detractor", value: modelNPS.detractors },
    ];
    return result;
  }
  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
      fillColor,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        {/* <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`PV ${value}`}</text> */}
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {` ${(percent * 100).toFixed(2)}%(${value})`}
        </text>
      </g>
    );
  };

  useEffect(() => {
    if (data) {
      const result = calculateMonthlyNPS(data);
      setFeedbackData(result);
    }
  }, [data]);

  return (
    <Card
      variant="outlined"
      sx={{
        boxShadow: 3,
        width: "100%",
        height: "320px",
        p: "15px",
        background: "#1d2637",
        color: "white",
        borderRadius: "12px",
        fontFamily: "Montserrat, serif !important",
      }}
    >
      <Typography
        sx={{ fontFamily: "Montserrat, serif !important" }}
        variant="overline"
        gutterBottom
      >
        NPS Category Distribution
      </Typography>
      <Divider />
      {/* <ResponsiveContainer width="100%" height="90%">
        <PieChart>
          <Pie
            data={feedbackData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={CustomLabel}
            outerRadius={90}
            innerRadius={60}
            dataKey="value"
          >
            {feedbackData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                color="white"
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer> */}
      <ResponsiveContainer width="90%" height="90%">
        <PieChart width={400} height={400}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={feedbackData}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
          >
            {feedbackData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                stroke="#1D2637" // Border color set to red
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default FeedbackPieChart;
