import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Header from "../components/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import FileUpload from "../components/FileUpload";

const AddCustomers = () => {
  const navigate = useNavigate();

  return (
    <div
      className="dark bg-gray-900 min-h-screen"
      style={{ backgroundColor: "#101827", minHeight: "100vh" }}
    >
      <Header />

      <div className="pt-12 dark">
        <div className="bg-[#1d2637] flex flex-col items-center w-2/3 m-auto p-3 rounded-sm text-white">
          <Button
            variant="text"
            color="primary"
            onClick={() => navigate("/dashboard/sales")}
            startIcon={<ArrowBackIcon />}
            sx={{ marginBottom: 2, alignSelf: "flex-start" }}
          >
            Back
          </Button>

          <Typography variant="h5" gutterBottom>
            Add Customers
          </Typography>
          <FileUpload />
        </div>
      </div>
    </div>
  );
};

export default AddCustomers;
